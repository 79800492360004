<div style="padding-left: 2rem; padding-right: 2rem">
  <div class="row">
    <div class="col-xs-12 d-md-none d-sm-block">
      <div class="gt-logo-mobile">
        <img
          ngSrc="../../../assets/images/Guard_Tracker-Stacked-{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
          width="160"
          height="160"
          priority
          as="image"
        />
      </div>
    </div>
    <div class="col-xs-12 d-none d-md-block">
      <div class="gt-logo-desktop">
        <img
          ngSrc="../../../assets/images/Guard_Tracker-Lateral_Long_{{
            currentTheme
          }}.avif"
          alt="Guard Tracker"
          width="384"
          height="96"
          priority
          as="image"
        />
      </div>
    </div>
  </div>

  <div class="text-center mt-5">
    <h2>Temporarily Down for Maintenance</h2>
    <p>
      Guard Tracker is temporarily unavailable due to maintenance, we will be
      back online soon.
    </p>
    <p class="text-center">
      Retrying in <span class="text-danger">{{ countDownValue }}</span> seconds
      ...
    </p>
  </div>
</div>
